import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { BlogPostData } from 'interfaces/BlogPostQueryData';

import * as Styled from './style';
import { formatDate } from '../utils';

const BlogPost = ({ mdx }: { mdx: BlogPostData }) => {
  const { 
    frontmatter: { 
      entryNumber, 
      title, 
      publishedAt, 
    }, 
    slug,
    body, 
    timeToRead,
  } = mdx; 

  return(
    <Styled.BlogPost>
      <Styled.Heading>{title}</Styled.Heading>
      <Styled.Caption>
        Entry {`${entryNumber}`.padStart(2, '0')} ✸{' '}
        {formatDate(publishedAt)} ✸{' '}
        {`${timeToRead}`.padStart(2, '0')} min read ✸{' '}
        <a 
          href={createGitHubUrl(slug)}
          target="_blank" 
          rel="noopener noreferrer"
        >
          Edit Post
        </a>
      </Styled.Caption>
      <Styled.Body>
        <MDXRenderer>{body}</MDXRenderer>
      </Styled.Body>
    </Styled.BlogPost>
  );
};

const createGitHubUrl = (slug: string) => (
  `https://github.com/Yihwan/yihwan-dot-kim-v2/blob/master/src/blog/${slug}/index.mdx`
);

export default BlogPost;
