import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/layout';
import BlogPost from 'components/blog/blogPost';
import BlogPostQueryData from 'interfaces/BlogPostQueryData';

const BlogPostPage = ({ 
  data, 
  location, 
}: { 
  data: BlogPostQueryData;
  location: Location;
}) => {
  const { mdx } = data; 

  return(
    <Layout 
      seo={{
        title: mdx.frontmatter.title,
      }}
      location={location}
    >
      <BlogPost mdx={mdx}/>
    </Layout>
  );
};

export const query = graphql`
  query BLOG_POST_QUERY($id: String) {
    mdx(id: {eq: $id}) {
      id
      frontmatter {
        entryNumber
        title
        publishedAt
      }
      slug
      body
      timeToRead
    }
  }
`;

export default BlogPostPage;