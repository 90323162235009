import styled from '@emotion/styled';

import { SPACER, BREAKPOINTS } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';

// TODO: Dry with blogIndex? 
export const BlogPost = styled.div`
  max-width: 1280px;
  padding: 16vh ${SPACER.base} ${SPACER.large};
  margin: 0 auto;
  border-left: 1px dashed;
  border-right: 1px dashed;
  min-height: 100vh;
`;

export const Heading = styled.h1<{ theme: CustomTheme }>`
  font-size: 64px;
  max-width: 875px;
`;

export const Caption = styled.div<{ theme: CustomTheme }>`
  font-family: ${({ theme }) => theme.fonts.monospace};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-top: ${SPACER.base};
`;

export const Body = styled.div`
  padding: ${SPACER.x2large} 0;

  .image-container {
    padding-bottom: ${SPACER.large};

    > span {
      width: 100%;
    }
  }

  .UNSAFE__side-by-side {
    display: grid; 
    grid-template-columns: repeat(auto-fit, minmax(min(256px, 100%), 1fr));
    grid-gap: ${SPACER.small};
  }
`;